import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const OpenSourcePage = (props) => (
  <Layout>
    <SEO title="Open Source" />
  </Layout>
);

export default OpenSourcePage;
